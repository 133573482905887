@charset "utf-8";

@import "minimal-mistakes/skins/dirt"; // skin
@import "minimal-mistakes"; // main partials

table  { width: 100%; }
thead  { background-color: transparent; border-top: 2px solid #afa58f; }
tr, td { border: none; }
a      { text-decoration: none; }


.masthead      { background-color: rgba(185,144,117,0.15); }
.greedy-nav    { background-color: transparent; }
.greedy-nav a  { color: #636363; }
//.page__footer  { background-color: #EBE4E0; }
.page__footer  { background-color: transparent; border-top: 1px solid #e9dcbe;}
.smallfont .page__content { font-size: 0.9em;}

